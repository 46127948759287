<template>
  <div class="soberania_alimentar_background" style="height: 100%">
    <section class="section__margin-bottom ">
      <v-row class="container mx-auto">
        <v-col class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="projeto_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center flex-column align-center">
        <img
          width="30%"
          src="../../assets/conteudo_cultura_alimentar_01.png"
          class="mt-4 title__img"
        />
      </v-row>
      <v-row class="d-flex justify-md-center align-center pt-5 pb-5 ovx">
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_cultura_alimentar' }">
            Segurança e Soberania Alimentar
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudo_soberania_beira_fogao' }">
            Beira do fogão
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudo_soberania_quintandas' }">
            As Quintandas
          </router-link>
        </v-col>
      </v-row>
      <router-view></router-view>
      <ScrollTop />
    </section>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
import ScrollTop from "@/components/ScrollTop.vue";

export default {
  components: { Breadcrumb, ScrollTop },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Cultura Alimentar",
          href: "conteudos_tematicos_cultura_alimentar",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }
  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px rgb(224, 105, 25, 0.6);
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: rgb(224, 105, 25, 0.8);
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
.soberania_alimentar_background {
  background-image: url("/backgrounds/conteudos_soberania_bg.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}

a {
  text-decoration: none;
  color: #426e51;
  font-size: 0.8em;
}

.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #426e51;
}

.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .title__img {
    width: 50%;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
